import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import "dayjs/locale/de";
import { JsonLd } from "gatsby-plugin-next-seo";
import React from "react";

export default function EventEmbedded({ event, hideProduction }) {
    let classes = `node--event--embedded`;
    if(!event.isFuture) {
        classes += ` node--event--past`;
    }
    dayjs.extend(timezone).extend(utc);
    const formattedShowtime = dayjs(event.field_showtime)
        .tz(`Europe/Berlin`)
        .locale(`de-de`)
        .format(`dd. DD.MM.YYYY, HH:mm [Uhr]`);
    let addressHtml = ``;
    let addressString = ``;
    let addressObject = event.relationships.field_location.field_address;
    if (addressObject.address_line1.length !== 0) {
        addressHtml += `${addressObject.address_line1}<br />`;
        addressString += `${addressObject.address_line1}, `;
    }
    if (addressObject.address_line2.length !== 0) {
        addressHtml += `${addressObject.address_line2}<br />`;
        addressString += `${addressObject.address_line2}, `;
    }
    if (
        addressObject.locality.length !== 0 &&
        addressObject.postal_code.length !== 0
    ) {
        addressHtml += `${addressObject.postal_code} ${addressObject.locality}`;
        addressString += `${addressObject.postal_code} ${addressObject.locality}`;
    }
    let attendanceMode = ``;
    switch (event.field_event_attendance_mode) {
        case `online`:
            attendanceMode = `https://schema.org/OnlineEventAttendanceMode`;
            break;
        case `mixed`:
            attendanceMode = `https://schema.org/MixedEventAttendanceMode`;
            break;
        default: 
            attendanceMode = `https://schema.org/OfflineEventAttendanceMode`;
    }
    return (
        <article className={classes} aria-label={event.relationships.field_production.title + `, ` + formattedShowtime + `, ` + event.relationships.field_location.title}>
            <JsonLd
                json={{
                    '@context': `https://schema.org`,
                    '@type':`TheaterEvent`,
                    'name': event.relationships.field_production.title + ` @ ` + event.relationships.field_location.title,
                    'startDate': event.field_showtime,
                    "workPerformed": {
                        "@type": `CreativeWork`,
                        "name": event.relationships.field_production.title,
                    },
                    "offers": {
                        "@type": `Offer`,
                        "url": event.field_tickets?.uri
                    },
                    "location": {
                        "@type": `PerformingArtsTheater`,
                        "name": event.relationships.field_location.title,
                        "address": addressString
                    },
                    "organizer": {
                        "@type": `Organization`,
                        "name": event.relationships.field_production.relationships.field_production_company[0]?.name,
                        "url": event.relationships.field_production.relationships.field_production_company[0]?.field_company_link?.uri
                    },
                    "eventAttendanceMode": attendanceMode,
                    "eventStatus": `https://schema.org/EventScheduled`,
                }}
            />
            {hideProduction === true ? 
                `` : 
                <h2 className="field field--name-field-production">
                    {event.relationships.field_production.title}
                </h2>
            }
            <div className="field field--name-field-showtime">
                <time dateTime={event.field_showtime}>{formattedShowtime}</time>
            </div>
            <div className="field field--name-field-location">
                <div className="location-title">
                    {event.relationships.field_location.title}
                </div>
                <div className="field field--name-field-address field--type-address">
                    <address
                        dangerouslySetInnerHTML={{ __html: addressHtml }}
                    ></address>
                </div>
            </div>
            {event.field_tickets && event.isFuture ? 
                <div className="field field--name-field-tickets">
                    <a href={event.field_tickets.uri} rel="noreferrer">
                        {event.field_tickets.title}
                    </a>
                </div>
                : ``
            }
        </article>
    );
}
