import * as React from "react"
import { graphql } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import Layout from "../components/layout"
import EventEmbedded from "../components/node/EventEmbedded";

const TerminePage = ({data}) => {
  const emptyPlaceholder = data.allNodeEvent.edges.length === 0 ? <p>Neue Termine in Kürze!</p> : ``;
  return (
    <Layout data={data}>
      <GatsbySeo
        title={`Termine | ` + data.site.siteMetadata.title + ` | ` + data.site.siteMetadata.slogan}
        description="Bevorstehende Termine mit Marc-Oliver Teschke, Schauspieler aus Düsseldorf und Neuss."
      />
      <h1>Termine</h1>
      <div className="view--all-events">
        {emptyPlaceholder}
        {data.allNodeEvent.edges.map((event, i) => {
          return(
            <EventEmbedded event={event.node} key={i} />
          );
        })}
      </div>
    </Layout>
  )
}

export const query = graphql`
query TerminePageQuery {
  site {
    ...SiteHeader
  }
  allNodeEvent(
    sort: {fields: field_showtime, order: ASC}
    filter: {isFuture: {eq: true}}
  ) {
    edges {
      node {
        field_showtime
        field_showtime_hide_time
        relationships {
          field_location {
            field_address {
              address_line1
              address_line2
              country_code
              locality
              organization
              postal_code
            }
            title
          }
          field_production {
            title
            relationships {
              field_production_company {
                name
                field_company_link {
                  uri
                }
              }
            }
          }
        }
        field_tickets {
          title
          uri
        }
        field_event_attendance_mode
        isFuture
      }
    }
  }
}
`

export default TerminePage